import React from "react";
import Header from "../components/Header";
import Pricing from "./Pricing";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

export default function Pricingpage() {
    return (
        <div className="pricing_route_page">
            <div>
                <Header />
                <Pricing />
                <div className="home_page_mian_div">
                    <div className="bg-img-div">
                        <div className="optimize_crm">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 my-auto">
                                        <div className="optimize_crm_content">
                                            <h1 className="h1 text-lg-start text-center">
                                                {/* Optimize CRM, Sales & Manage Leads with Teacher's <span>Aid</span> */}
                                                Never lose a Lead, <br /> Message, or Sale <br /> On Skool again!
                                            </h1>

                                            <p className="py-4 pe-lg-2 text-lg-start text-center">
                                                Mini Skool Messenger CRM that helps you manage your <br /> inbox, follow up with prospects, get more sales & build your <br /> community.</p>

                                            <div className="button_content_box">
                                                <div className="gradiant-bg-btn">
                                                    <button>
                                                        <img src="/images/chrome-store.svg" alt="Chrome" />
                                                        Try today
                                                    </button>
                                                </div>
                                                <p className="pt-2 text-lg-start text-center">Teacher’s Aid turns conversations to conversions</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        {/* <div className="img-right hero_Section_img">
                  <img
                    src="/images/Grid3.png"
                    alt="hero frame"
                    className="img-fluid"
                  />
                </div> */}
                                        <div className="optimize_video_box">
                                            <iframe
                                                src="https://www.youtube.com/embed/98-tPddG9VI?autoplay=1&loop=1&playlist=98-tPddG9VI&mute=1"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="encrypted-media; autoplay"
                                                allowFullScreen
                                            ></iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        {/* Grid Sections 1 */}

                        <div className="grid_sections">
                            <div className="container">
                                <div className="global-padding">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-7 my-auto">
                                            <div className="img-left">
                                                <img src="/images/Grid1.png" alt="improving CRM" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-5 my-auto">
                                            <div className="grid_content ps-lg-2">
                                                <h2>Organize, Track, and Close Deals Faster with Teacher’s Aid</h2>
                                                <p className="py-3">Teacher’s Aid makes it easy to manage your contacts, organize conversations, and never miss an opportunity to follow up.</p>
                                                <div className="flex_grid_content_box">
                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>1</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Organize your inbox with custom tags</p>
                                                            <span>Simplify your chats with label leads, follow-ups, and prospects for easy tracking so you always know where to focus next.</span>
                                                        </div>
                                                    </div>

                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>2</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Add and View Notes</p>
                                                            <span>Keep track of every detail. Attach notes to conversations to remember important insights and next steps. Personalized follow-ups keep you prepared.</span>
                                                        </div>
                                                    </div>

                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>3</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Set Smart Reminders</p>
                                                            <span>Create reminders for follow-ups and tasks so you’re always on schedule turning opportunities into results.</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="white-bg-btn">
                                                    <button>
                                                        <img src="/images/chrome-store.svg" alt="Chrome" />
                                                        Start Closing More Deals
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Grid Sections 2 */}

                        <div className="grid_sections">
                            <div className="container">
                                <div className="global-padding">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-5 my-auto order-2 order-lg-first">
                                            <div className="grid_content pe-lg-5">
                                                <h2>Teams love Teacher’s Aid</h2>
                                                <p className="py-3">Everything your team needs to track, organize, and convert leads—without getting overwhelmed by complex tools.</p>
                                                <div className="flex_grid_content_box">
                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>1</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Stay on the same page, always.</p>
                                                            <span>Easily share tags, organize leads, and keep everyone on the same page with real-time updates across your team.</span>
                                                        </div>
                                                    </div>

                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>2</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Keep teams organized and secure.</p>
                                                            <span>Assign role-based profiles and permissions to ensure every team member has access to the right tools and information—nothing more, nothing less.</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="white-bg-btn">
                                                    <button>
                                                        <img src="/images/chrome-store.svg" alt="Chrome" />
                                                        Invest in your team
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-xl-7 my-auto order-1 order-lg-last">
                                            <div className="img-right m-0 mb-lg-0 mb-5">
                                                <img src="/images/Grid2.png" alt="collaborate" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Grid Sections 3 */}

                        <div className="grid_sections">
                            <div className="container">
                                <div className="global-padding">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-7 my-auto">
                                            <div className="img-left">
                                                <img src="/images/Grid3.png" alt="leads management" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-5 my-auto">
                                            <div className="grid_content ps-lg-2">
                                                <h2>Boost Your Sales with Automated Lead Tracking</h2>
                                                <p className="py-3">Track every lead, set smart reminders, custom notes to ensure no opportunity is missed. Spend less time tracking and more time closing deals.</p>
                                                <div className="flex_grid_content_box">
                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>1</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Keep Every Lead on Your Radar</p>
                                                            <span>Track every interaction and see exactly where each lead stands in your sales pipeline. Stay organized and never lose sight of opportunities.</span>
                                                        </div>
                                                    </div>

                                                    <div className="grid_listbox_flexbox">
                                                        <div className="icon_box">
                                                            <p>2</p>
                                                        </div>
                                                        <div className="grid_flex_content">
                                                            <p>Keep teams organized and secure.</p>
                                                            <span>Set smart reminders to connect at the perfect time and close deals faster. Automate the busy work so you can focus on what matters most—growing your sales.</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="white-bg-btn">
                                                    <button>
                                                        <img src="/images/chrome-store.svg" alt="Chrome" />
                                                        Start growing your sales today
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-img-skool-section">
                            {/* Skool Section  */}
                            {/* <div className="skool_section">
          <div className="container">
            <div className="global-padding">
              <h2>
                Empowers you with seamless <span><img src="/images/skool.png" alt="skool" /></span> integration, helping <br /> you manage your community, CRM, sales, and leads—all <br /> organized, tracked, and always in sync.
              </h2>
            </div>
          </div>
        </div> */}

                            {/* Cards Section */}

                        </div>          
                </div>
            </div>
            <Footer />
        </div>
    )
}